// colors
[data-dark="false"] {
  --primary: #0ea5e9;
  --secondary: #7dd3fc;
  --text: #000000;
  --background: #ffffff;
  --background-alt: #fafafa;
  --light-gray: #e0e0e0;
  --gray: #808080;
  --overlay: #00000020;
}
[data-dark="true"] {
  --primary: #0ea5e9;
  --secondary: #075985;
  --text: #ffffff;
  --background: #181818;
  --background-alt: #1c1c1c;
  --light-gray: #404040;
  --gray: #808080;
  --overlay: #ffffff10;
}

:root {
  // font families
  --title: "Montserrat", sans-serif;
  --heading: "Montserrat", sans-serif;
  --body: "Montserrat", sans-serif;
  --code: "Montserrat", monospace;

  // font sizes
  --medium: 1rem;
  --large: 1.2rem;
  --xl: 1.4rem;
  --xxl: 1.6rem;

  // font weights
  --thin: 200;
  --regular: 400;
  --semi-bold: 500;
  --bold: 600;

  // text line spacing
  --spacing: 2;

  // effects
  --rounded: 3px;
  --shadow: 0 0 10px 0 var(--overlay);
}
